<template>
    <div class="Page news">
        <div class="news-header">
            <div class="poppins news-header-title">{{ $t('news') }}</div>
        </div>

        <div class="news-list">
            <div class="news-item" v-for="item in list" :key="item.id">
                <div class="news-item-image">
                    <img :src="require(`@assets/images/about/newsDetail/${item.id}/${item.img}`)" alt="" />
                </div>
                <div class="news-item-info">
                    <div class="title">{{ item.title }}</div>
                    <div class="date" v-if="item.date">{{ item.date }}</div>
                    <div class="desc">{{ item.desc }}</div>
                    <a v-if="item.linkId" href="javascript:;" class="poppins more" @click="onMore(item)">{{ $t('more') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news',
    data() {
        return {
            pageDurationID: 'Aboutus_News',
        };
    },
    computed: {
        newsI18n() {
            return this.$t('newsFile');
        },
        list() {
            let { list, news } = this.newsI18n;
            let listMap = {};
            list.forEach(item => {
                listMap[item.id] = item;
            });
            let result = [];
            news.forEach(item => {
                if (listMap[item]) {
                    result.push(listMap[item]);
                }
            });
            return result;
        },
    },
    methods: {
        onMore(data) {
            if (data.linkId) {
                this.$track('aboutus_news_clk', {
                    news_id: data.id,
                });
                this.$router.push({
                    name: 'newsDetail',
                    params: {
                        id: data.id,
                    },
                });
            }
        },
    },
    mounted() {
        this.$track('aboutus_news_imp');
    },
};
</script>

<style lang="scss">
.news {
    background: #fff;
    @media only screen and (min-width: 769px) {
        width: 740px;
        margin: 0 auto;

        &-header {
            display: flex;
            padding: 68px 0;
            &-title {
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #00C8C8;
                    left: 0;
                    bottom: -8px;
                }
            }
        }

        &-item {
            display: flex;
            margin-bottom: 80px;

            &-image {
                width: 302px;
                flex-shrink: 0;
                margin-right: 50px;

                img {
                    display: block;
                    width: 100%;
                }
            }
            &-info {
                display: flex;
                flex-direction: column;
                .title {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .date {
                    font-size: 16px;
                    line-height: 20px;
                    color: #999;
                    margin-bottom: 15px;
                }
                .desc {
                    font-size: 12px;
                    line-height: 16px;
                    color: #333;
                    margin-bottom: 15px;
                }
                .more {
                    padding: 0 30px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: solid 1px #000;
                    border-radius: 20px;
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    margin-right: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 1.1rem 0.3rem 0.8rem 0.3rem;
        &-header {
            display: none;
        }
        &-item {
            margin-bottom: 0.5rem;
            &:last-of-type {
                margin-bottom: 0;
            }

            &-image {
                margin-bottom: 0.15rem;
                img {
                    display: block;
                    width: 100%;
                }
            }
            &-info {
                .title {
                    font-size: 0.14rem;
                    font-weight: 600;
                    margin-bottom: 0.15rem;
                }
                .date {
                    font-size: 0.14rem;
                    color: #999;
                    margin-bottom: 0.15rem;
                }
                .desc {
                    display: none;
                }
                .more {
                    width: 1.2rem;
                    height: 0.32rem;
                    border: solid 1px #000;
                    border-radius: 0.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.14rem;
                    font-weight: 500;
                    color: #000;
                }
            }
        }
    }
}
</style>
