<template>
    <div class="Page news-detail">
        <div class="news-detail-content">
            <h1 class="news-detail-title">{{ detail.title }}</h1>
            <div class="news-detail-des">{{ detail.desc }}</div>
            <div class="news-detail-author">
                <img :src="author.img" alt="" class="news-detail-author-img">
                <div class="news-detail-author-text">{{ author.text }}</div>
            </div>
            <template v-for="(item, index) in content">
                <div v-if="isText(item)" :key="index" class="news-detail-text" v-html="item"></div>
                <div v-else-if="isImg(item)" :key="index" class="news-detail-img-wrap" :class="{'no-tit': !item.text}">
                    <img :src="item.img" alt="" class="news-detail-img" :style="item.style" />
                    <div class="news-detail-img-tit" v-if="item.text">{{ item.text }}</div>
                </div>
                <div v-else-if="isClassText(item)" :key="index" class="news-detail-text" :class="item.class">{{ item.text }}</div>
            </template>
        </div>
        <div class="news-detail-footer" v-if="relatedArticles.length">
            <div class="news-detail-footer-con">
                <div class="news-detail-footer-tit">{{ i18nPage.related_articles }}</div>
                <div class="news-detail-footer-list">
                    <a
                        v-for="item in relatedArticles" :key="item.id"
                        @click="onRelatedArticle(item)"
                        class="news-detail-footer-item"
                        :class="{'visited': activeIdsMap[`${lang}_${item.id}`]}"
                    >
                        <div class="news-detail-footer-item-tit">{{ item.title }}</div>
                        <div class="news-detail-footer-item-des">{{ item.desc }}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'newsDetail',
    data() {
        return {
            detail: {},
            content: [], // 文章内容
            // 科技馆的文章需要is_cover:1
            // 提交过新闻表单则不再展示表单
            activeIdsMap: {}, // 点击过的文章
        };
    },
    computed: {
        ...mapState({
            lang: state => state.route.params.lang,
        }),
        i18nPage() {
            return this.$t('newsFile');
        },
        author() {
            const author = this.detail.author || {};
            const imgName = author.img ? author.img : 'eve.png';
            return {
                ...author,
                img: this._img(imgName),
                text: author.text || this.$t('metaInfo.title')
            };
        },
        relatedArticles() {
            // 先去取related_articles，取不到的时候再同时取上/下一篇文章作为关联文章
            let ids = this.detail.related_articles || [];
            const lists = this.i18nPage.list || [];
            if (ids.length) {
                return ids.map(id => lists.find(item => item.id == id)).filter(i => !!i);
            }
            const { id } = this.$route.params;
            let newList = [];
            const index = lists.findIndex(item => item.id == id);
            let prev = index - 1;
            let prevItem;
            // console.log('====index', index)
            while (prev >= 0 && !prevItem) {
                const item = lists[prev];
                // console.log('=====prev', prev, item)
                if (item && item.linkId) {
                    prevItem = item;
                    break;
                } else {
                    prev -= 1;
                }
            }
            let next = index + 1;
            let nextItem;
            while (next < lists.length && !nextItem) {
                const item = lists[next];
                // console.log('=====next', next, item)
                if (item && item.linkId) {
                    nextItem = item;
                    break;
                } else {
                    next += 1;
                }
            }
            if (prevItem) newList.push(prevItem);
            if (nextItem) newList.push(nextItem);
            // console.log('====newList', newList)
            return newList;
        },
    },
    watch: {
        '$route.params.id'() {
            this.$nextTick(() => {
                this.reset();
            });
        },
        'lang'(val) {
            this.$nextTick(() => {
                this.reset();
            });
        },
    },
    methods: {
        _img(name) {
            return require('@assets/images/about/newsDetail/' + name);
        },
        isText(item) {
            return typeof(item) === 'string';
        },
        isImg(item) {
            return item && item.img;
        },
        isClassText(item) {
            return item && item.class && item.text;
        },
        onRelatedArticle(item) {
            const id = item.id;
            if (!id) return;
            try {
                const idMap = {
                    ...this.activeIdsMap,
                    [`${this.lang}_${id}`]: 1,
                };
                localStorage.setItem('eve_active_news_ids', JSON.stringify(idMap));
            } catch (err) {
                console.error('onRelatedArticle', err);
            }
            this.$router.push({
                name: 'newsDetail',
                params: {
                    id,
                },
            });
        },
        genderChange(data) {
            this.gender = data.id;
        },
        onFavorite(item, index) {
            let { favorite } = this;
            favorite[index] = {
                ...item,
                checked: !item.checked
            };
            this.favorite = [...favorite];
        },
        reset() {
            this.isTrack = false;
            const { id } = this.$route.params;
            const { i18nPage } = this;
            if (i18nPage && i18nPage.details && i18nPage.details[id]) {
                this.detail = this.i18nPage.details[id];
                this.isCover = this.detail.is_cover && localStorage.getItem('eve_submit_news') !== '1';
                this.activeIdsMap = JSON.parse(localStorage.getItem('eve_active_news_ids')) || {};
                this.content = this.getContent();

                if (this.detail) {
                    setTimeout(() => {
                        if (this.detail.pageTitle) {
                            document.title = this.detail.pageTitle;
                        }
                        if (this.detail.pageDescription) {
                            document.querySelector(`meta[name="description"]`).setAttribute('content', this.detail.pageDescription);
                        }
                    }, 0);
                }
            } else {
                console.log('找不到对应的文章内容', id);
                this.$router.replace({
                    name: 'news',
                });
            }
        },
        getContent() {
            const { id } = this.$route.params;
            let list = this.detail.content || [];
            return list.map(item => {
                if (item && item.img) {
                    return {
                        ...item,
                        img: this._img(id + '/' + item.img)
                    }
                }
                return item
            });
        },
        scrollEvent() {
            clearTimeout(this.scrollTimer);
            this.scrollTimer = setTimeout(() => {
                if (!this.contentHei) {
                    const el = this.$el.querySelector('.news-detail-content');
                    if (!el) return;
                    const bot = parseInt(getComputedStyle(el).paddingBottom);
                    this.contentHei = el.offsetHeight - bot - 20;
                }
                const top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                // console.log('======scrollEvent', top)
                if (top + this.winHei >= this.contentHei) {
                    console.log('已经滑动到文章底部')
                    this.isTrack = true;
                    this.$track('AboutUs_incident_pagebottom_imp', {
                        title: this.$route.params.id,
                    });
                }
            }, 100);
        }
    },
    created() {
        this.reset();
    },
    mounted () {
        this.winHei = window.innerHeight;
        document.addEventListener('scroll', this.scrollEvent);
    },
    beforeDestroy() {
        clearTimeout(this.scrollTimer);
        document.removeEventListener('scroll', this.scrollEvent);
    },
};
</script>

<style lang="scss">
.news-detail {
    color: #000;
    .bold {
        font-weight: 500;
    }
    &-content {
        position: relative;
    }
    &-breads {
        font-size: 14px;
        line-height: 18px;
        color: #7e7e7e;
        display: flex;
        .split {
            margin: 0 10px;
        }
        .active {
            color: #000;
            font-weight: 500;
        }
    }
    &-title {
        font-size: 40px;
        line-height: 56px;
        font-weight: 500;
        padding: 30px 0 15px;
    }
    &-des {
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.4);
        padding-left: 10px;
        margin-bottom: 30px;
        position: relative;
        &::before {
            content: '';
            width: 1px;
            height: 16px;
            background: #979797;
            position: absolute;
            top: 7px;
            left: 0;
        }
    }
    &-author {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        &-img {
            width: 25px;
            height: 25px;
            // border-radius: 50%;
            margin-right: 13px;
        }
        &-text {
            font-size: 16px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.5);
        }
    }
    &-text {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        margin: 30px 0;
    }
    &-img-wrap {
        margin-top: 50px;
        margin-bottom: 30px;
        &.no-tit {
            margin-bottom: 50px;
        }
    }
    &-img {
        width: 100%;
        border-radius: 18px;
        display: block;
        &-tit {
            margin-top: 10px;
            font-size: 18px;
            line-height: 25px;
            color: #999;
            text-align: center;
        }
    }
    &-footer {
        background: #f9f9f9;
        &-tit {
            font-weight: 500;
            font-size: 40px;
            line-height: 56px;
            margin-bottom: 60px;
        }
        &-item {
            display: block;
            color: #000;
            &.visited {
                opacity: 0.4;
            }
            &:not(:last-child) {
                margin-bottom: 70px;
            }
            &-tit {
                font-weight: 500;
                font-size: 26px;
                line-height: 37px;
                margin-bottom: 16px;
                display: flex;
                &::before {
                    content: '';
                    width: 4px;
                    height: 20px;
                    background: #1fc8d1;
                    margin-right: 14px;
                    margin-top: 9px;
                }
            }
            &-des {
                padding-left: 18px;
                font-size: 14px;
                line-height: 26px;
                color: rgba(0, 0, 0, 0.4);
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    .error-item {
        margin-top: 10px;
    }
    &.is-cover {
        .news-detail-content {
            padding-bottom: 0;
        }
    }
    @media only screen and (min-width: 769px) {
        $maxWid: 1120px;
        &-content {
            max-width: $maxWid;
            padding: 60px 20px 145px - 30px;
            margin: 0 auto;
        }
        &-img {
            max-width: 600px;
            margin: 0 auto;
        }
        &-breads {
            padding-top: 30px;
        }
        &-footer {
            &-con {
                max-width: $maxWid;
                padding: 80px 20px 110px;
                margin: 0 auto;
            }
            &-item {
                cursor: pointer;
                &:hover {
                    .news-detail-footer-item-tit {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-content {
            padding: 100px 20px 80px;
        }
        &-breads {
            padding-top: 25px;
        }
        &-title {
            font-size: 24px;
            line-height: 35px;
            padding: 30px 0 20px;
        }
        &-des {
            font-size: 15px;
            line-height: 24px;
            padding-left: 9px;
            margin-bottom: 20px;
            &::before {
                top: 4px;
            }
        }
        &-author {
            margin-bottom: 40px;
            &-img {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }
            &-text {
                font-size: 14px;
                line-height: 18px;
            }
        }
        &-text {
            font-size: 16px;
            line-height: 27px;
            margin: 27px 0;
        }
        &-img-wrap {
            margin-top: 40px;
            margin-bottom: 20px;
            &.no-tit {
                margin-bottom: 40px;
            }
        }
        &-img {
            border-radius: 8px;
            &-tit {
                margin-top: 10px;
                font-size: 16px;
            }
        }
        &-footer {
            &-con {
                padding: 40px 20px 80px;
            }
            &-tit {
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 37px;
                text-align: center;
            }
            &-item {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                &-tit {
                    font-size: 19px;
                    line-height: 26px;
                    margin-bottom: 10px;
                    &::before {
                        margin-top: 3px;
                    }
                }
            }
            &-tip {
                margin-bottom: 25px;
            }
        }
    }
}
</style>
